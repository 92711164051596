.header-container {
  height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
}

.header-container:hover {
  cursor: pointer;
}

.profile-img {
  background-image: url("../../assets/images/profile.jpg");
  background-size: cover;
  background-position: center;
  width: 60px;
  border-radius: 50%;
}

.logout-btn:hover {
  cursor: pointer;
}