.step-number{
    color: #999999;
    font-weight: bold;
}

.step-title {
    color: #0a539c;
    font-weight: bold;
    font-size: 25px;
}

.step2-form-container {
    margin-top: 50px;
}

.venue-url-container {
    background-color: #fbf0f0;
    border: #bd0003;
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px;
    padding: 15px;
}

.venue-url-warning {
    color: #bd0003;
}

.venue-name-input {
    width: 100%;
}

.step2-action-container {
    margin-top: 50px;
}

.continue-btn {
    margin-left: 20px;
}

.form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form-field label {
    margin-bottom: 0.5rem;
  }