.sidebar-nav {
  height: 100vh;
  background-color: white;
  padding-top: 1rem;
  padding-right: 1rem;
  width: 360px;
}

.sidebar-item a {
  text-align: left;
  font-size: 1rem;
  margin-top: 0;
  padding-top: 1rem;
}

.nav-link {
  margin-left: 1rem;
}

.wrapped-component-container {
  margin: 30px;
  width: 100%;
}
