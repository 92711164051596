.p-card {
    /* Adjust width as needed */
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .p-card-header {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
  }
  
  .p-field {
    margin-bottom: 1rem;
  }
  
  .p-field label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .p-field-checkbox {
    display: flex;
    align-items: center;
  }
  
  .p-button-text {
    background-color: transparent;
    border: none;
    color: #333;
    padding: 0.5rem 1rem;
  }
  
  a {
    color: #333;
    text-decoration: none;
    font-size: 0.875rem;
    display: block;
    margin-top: 1rem;
    text-align: right;
  }