:root {
  font-size: 16px;
}

.page-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 25px;
}

.wrapper {
  width: 100%;
}

.sidebar-form {
  width: 600px;
}

.edit-row:hover {
  cursor: pointer;
}

.delete-row:hover {
  cursor: pointer;
}

.login-page-container {
  background-color: white;
}

.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 50%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}

.p-inputtext {
  height: 45px;
}