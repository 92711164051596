.org-table-container {
    background-color: white;
}

.org-mngmt-table {
    margin: 20px;
}

.org-table-header {
    font-size: 20px;
    margin: 20px;
    font-weight: bold;
    padding-top: 10px;
}